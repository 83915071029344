<template>
  <div>
    <br /><br />
    <div class="loading" v-if="fetchData">
      <load-data></load-data>
    </div>
    <b-container v-else>
      <b-row>
        <b-col md="12">
          <div
            class="d-flex border border-info rounded p-2 justify-content-center"
          >
            <h4 style="font-size: 18px; font-weight: bolder">
              Survey Name:<span class="text-info"> {{ survey.name }}</span>
            </h4>
            <h6 class="ml-2" style="font-size: 18px; font-weight: bolder">
              Survey Type: <span class="text-info">Course Exit Survey</span>
            </h6>
          </div>

          <br />

          <h6 class="text-danger" v-if="answerPostData.length == 0">
            Survey Questions not assigned yet, Please check back later!
          </h6>
          <b-form @submit.prevent="preparePostDataForSurveyAnswers()" v-else>
            <div class="choiceOptions">
              <b-overlay
                :show="answerSurvey"
                spinner-variant="primary"
                spinner-type="grow"
                spinner-small
                rounded="md"
              >
                <div
                  v-for="(choice, choiceIndex) in answerPostData"
                  :key="choiceIndex"
                >
                  <div class="border border-info rounded p-3 mb-3">
                    <div
                      class="questionNoAndQuestion d-flex align-items-center"
                    >
                      <p style="font-size: 16px; font-weight: bolder">
                        {{ choice.question_no }}:
                      </p>
                      <p style="font-size: 16px; font-weight: 600" class="ml-2">
                        {{ choice.name }}
                        <span v-if="choice.course_outcome_id">
                          <b-badge
                            variant="warning"
                            class="text-white"
                            :id="'cOutcome' + choiceIndex"
                            >{{
                              courseOutcomesOptions[choice.course_outcome_id]
                                .name
                            }}</b-badge
                          >
                          <b-tooltip
                            :target="'cOutcome' + choiceIndex"
                            triggers="hover"
                          >
                            {{
                              courseOutcomesOptions[choice.course_outcome_id]
                                .description
                            }}
                          </b-tooltip>
                        </span>
                      </p>
                    </div>
                    <div class="options">
                      <b-form-radio
                        style="font-size: 14px; font-weight: 600"
                        v-for="(option, optionIndex) in choice.choices"
                        :key="optionIndex"
                        v-model="choice.survey_choice_id"
                        :value="option.id"
                        :class="{
                          'form-group-error':
                            $v.answerPostData.$each[choiceIndex]
                              .survey_choice_id.$error,
                        }"
                        >{{ option.name }}</b-form-radio
                      >
                      <span
                        class="input-error-message"
                        v-if="
                          !$v.answerPostData.$each[choiceIndex].survey_choice_id
                            .required &&
                            $v.answerPostData.$each[choiceIndex]
                              .survey_choice_id.$dirty
                        "
                        >Please Select One!</span
                      >
                    </div>
                    <div
                      class="feedbackInputField"
                      v-if="
                        choice.allow_descriptive == 1 ||
                          choice.choices.length == 0
                      "
                    >
                      <b-form-input
                        v-model="choice.answer"
                        placeholder="Write Feedback....."
                        :class="{
                          'form-group-error':
                            $v.answerPostData.$each[choiceIndex].answer.$error,
                        }"
                      ></b-form-input>
                      <span
                        class="input-error-message"
                        v-if="
                          !$v.answerPostData.$each[choiceIndex].answer
                            .required &&
                            $v.answerPostData.$each[choiceIndex].answer.$dirty
                        "
                        >Please Provide a feedback!</span
                      >
                    </div>
                  </div>
                </div>
              </b-overlay>
            </div>
            <br />
            <div class="submitAnswers text-center">
              <b-button
                variant="primary"
                size="md"
                type="submit"
                :disabled="answerBtn.disabled"
                >{{ answerBtn.text }}</b-button
              >
            </div>
          </b-form>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import { required, requiredIf } from "vuelidate/lib/validators";
export default {
  async created() {
    await this.getSurvey();
  },
  data() {
    return {
      fetchData: false,
      answerSurvey: false,
      survey: {},
      choices: [],
      selected: [],
      surveyData: [],
      answerPostData: [],
      courseTeacherCombination: [],
      answerBtn: {
        text: "Submit",
        disabled: false,
      },
      questionsAndChoices: [], //testing------------
      answers: {}, // testing---------------
      surveyAnswers: {},
      courseOutcomesOptions: {},
    };
  },
  validations: {
    surveyAnswers: {
      required: requiredIf(function() {}),
    },
    answerPostData: {
      required,
      $each: {
        answer: {
          required: requiredIf(function($each) {
            if ($each.choices.length == 0) {
              return true;
            }
          }),
        },
        survey_choice_id: {
          required: requiredIf(function($each) {
            if ($each.choices.length != 0) {
              return true;
            }
          }),
        },
      },
    },
  },

  // {course_id:{ teacher_id:{ question_id:{here is the ndata}, question_id2:{}, ...}, teacher_id2:{}, ....}, course_id2:{},....}
  methods: {
    //********* Prepare Post Data for Survey Answers **********/
    async preparePostDataForSurveyAnswers() {
      await this.$v.$touch();
      if (this.$v.$invalid) {
        this.$smoothScroll({
          scrollTo: document.querySelector(".form-group-error"),
          duration: 1000,
          offset: -120,
        });
        this.submitStatus = "ERROR";
      } else {
        this.answerBtn.text = "Please wait...";
        this.answerBtn.disabled = true;
        this.answerSurvey = true;
        let surveyAnswers = await {
          studfbsurveyanswers: [],
        };

        this.answerPostData.forEach((question) => {
          surveyAnswers.studfbsurveyanswers.push({
            course_id: question.course_id,
            teacher_id: question.teacher_id,
            survey_question_id: question.survey_question_id,
            survey_choice_id: question.survey_choice_id,
            answer: question.answer,
          });
        });

        const submitSurveyAnswers = await this.submitSurveyAnswers(
          surveyAnswers
        );
        if (submitSurveyAnswers) {
          this.answerBtn.text = "Submitted";
          this.surveyAnswers = false;
          await this.$toast.success("Responses Submittd, Thank you :)", {
            position: "top",
            duration: 4000,
          });
          this.fetchData = await true;
          await this.$router.push({ name: "institute" });
        }
        if (!submitSurveyAnswers) {
          this.$toast.error("Something went wrong, Please try again!", {
            position: "top",
            duration: 3000,
          });
          this.clearResponse();
        }
      }
    },
    //********* Submit Survey Answers with API **********/
    async submitSurveyAnswers(surveyAnswers) {
      const url =
        this.$store.getters.getAPIKey.studentFeedbackSurvey +
        `/${this.survey.id}/studfbsurveyanswer`;
      let status = null;
      await this.$axios
        .post(url, surveyAnswers)
        .then((response) => {
          response;
          status = true;
          response;
        })
        .catch((error) => {
          error;
          status = true;
          // error;
        });
      return status;
    },
    //********* Getting Survey Details **********/
    async getSurvey() {
      const surveyId = await this.$route.params.survey_id;
      this.fetchData = true;
      const url =
        this.$store.getters.getAPIKey.studentFeedbackSurvey + `/${surveyId}`;
      await this.$axios
        .get(url)
        .then((response) => {
          this.survey = response.data;
          this.getCourseOutcomes();
          this.startSurvey();

          response;
        })
        .catch((error) => {
          error;
          error;
        });
    },
    //********* Start Survey **********/
    async startSurvey() {
      const url =
        this.$store.getters.getAPIKey.studentFeedbackSurvey +
        `/${this.survey.id}/start`;
      await this.$axios
        .get(url)
        .then((response) => {
          response;
          this.answerPostData = [];
          this.courseTeacherCombination = [];

          this.surveyData = response.data.questionsChoices;
          this.courseTeacherCombination = response.data.surveyCourceTeachers;
          this.questionsAndChoices = response.data.questionsChoices; // testing-------

          this.surveyData.forEach((question) => {
            this.answerPostData.push({
              course_id: null,
              teacher_id: null,
              survey_question_id: question.id,
              survey_choice_id: "",
              answer: "",
              question_no: question.question_no,
              name: question.name,
              allow_descriptive: question.allow_descriptive,
              answer_required: question.answer_required,
              choices: question.choices,
              course_outcome_id: question.course_outcome_id,
            });
          });

          response;
          //   this.prepareSurveyAnswersArray();
          this.fetchData = false;
        })
        .catch((error) => {
          error;
        });
    },
    //********* Get Course Outcomes **********/
    getCourseOutcomes() {
      let outcomeURL = this.$store.getters.getAPIKey.getCourseOutcomes;
      const url = outcomeURL.replace("course_id", this.survey.course_id);
      this.$axios
        .get(url)
        .then((response) => {
          const outcomes = response.data;
          this.courseOutcomesOptions = {};
          outcomes.forEach((outcome) => {
            this.$set(this.courseOutcomesOptions, outcome.id, {});
            this.$set(
              this.courseOutcomesOptions[outcome.id],
              "name",
              outcome.code
            );
            this.$set(
              this.courseOutcomesOptions[outcome.id],
              "description",
              outcome.outcome
            );
          });

          response;
        })
        .catch((error) => {
          error;
        });
    },
    clearResponse() {
      this.answerBtn.text = "Submit";
      this.surveyAnswers = false;
      setTimeout(() => {
        this.answerBtn.disabled = false;
      }, 3000);
    },
  },
};
</script>
